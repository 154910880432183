<template>
  <div class="container-fluid pb-5">
    <div class="row mx-0 position-absolute">
      <div class="ct-col col-lg-8 p-0 ">
        <div class="col-md-12">
          <Transition name="brand-logo" appear>
            <div class="brand-logo-mob text-left">
              <img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid">
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div class="row pb-5 mb-5">
      <div class="col-12">
        <h1>INVENTORY CHART</h1>
      </div>
      <div class="col-lg-12 mb-4" v-for="(wing, wingName) in inventories">
        <h2>WING C</h2>
        <div class="row px-1">
          <div class="col-12 wrapper">
            <div class="row no-gutters">
              <div class="col-12 col-sm-6 col-xl-3 mb-4" v-for="(category, categoryName, index) in wing">
                <h3>{{ categoryName }}</h3>
                <div class="inner-wrapper row no-gutters head">
                  <div class="col-3 unit-name" >UNIT</div>
                  <div class="col-3 unit-value" >CONF</div>
                  <div class="col-3 unit-value" >MOFA</div>
                  <div class="col-3 unit-value" >RERA</div>
                </div>
                <div class="inner-wrapper row no-gutters" :class="item.status" v-for="item in category">
                  <div class="col-3 unit-name" >{{ item.name }}</div>
                  <div class="col-3 unit-value" >{{ item.configuration }}</div>
                  <div class="col-3 unit-value" >{{ item.carpet_area }}</div>
                  <div class="col-3 unit-value" >{{ item.sale_area }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button v-b-modal.otpForm type="button" class="btn btn-proceed shadow" >
        Proceed &nbsp;
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6673 15L10.5007 13.7917L13.459 10.8333H3.33398V9.16667H13.459L10.5007 6.20833L11.6673 5L16.6673 10L11.6673 15Z" fill="#0499B7"/>
        </svg>
    </button>
    </div>
    <OtpForm />
  </div>
</template>
<script>
import OtpForm from '@/components/OtpForm.vue';

export default {
  components: {
    OtpForm,
  },
  data() {
    return {
      selectedWing: {},
      selectedFlat: {},
      inventories: {}
    }
  },
  created() {
    let project_name = 'SHUBHAM ARTESIA (VASHI)'
    this.$axios.get(`inventories/get_flats?project_name=${project_name}`).then(res => {
      if(res.data){
        this.inventories = res.data;
        this.$store.dispatch('setLoading', false);
      }
    })
  },
  mounted(){
    
  },
  methods: {
  },
  computed: {
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    }
  },
};
</script>
<style scoped>


.btn-proceed{
  position: fixed;
  left: 50%;
  bottom: 16px;
  z-index: 10;
  background-color: #fff;
  border-radius: 100px;
  padding: 16px 100px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1040;
}


  .image-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1031;
}

.brand-logo-mob {
  display: block;
  position: fixed;
  top: 0;
  z-index: 1033;
}

.image-cover-leave-from {
  opacity: 1;
}

.image-cover-leave-active {
  transition: opacity 0.5s ease-out 0.5s;
}

.image-cover-leave-to {
  opacity: 0;
}

h1{
  font-size: 13px;
  font-weight: 600;
  line-height: 16.38px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  padding-bottom: 28px;
}

h2{
  font-size: 13px;
  font-weight: 600;
  line-height: 16.38px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
} 

h3{
  font-size: 13px;
  font-weight: 600;
  line-height: 16.38px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.wrapper{
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  background-color: rgba(250, 250, 250, 1);
  padding: 16px 6px;
  border-radius: 12px;

}

.inner-wrapper{
  border: 1px solid rgba(104, 104, 104, 0.16);
  border-radius: 4px;
  padding: 10px 8px;
  margin: 0 3px 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}

.inner-wrapper.head{
  border: transparent;
  box-shadow: none
}

.inner-wrapper.Booked{
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c2c7 !important;
}

.inner-wrapper.Blocked{
  background-color: #d3d3d4 !important;
  border-color: #bcbebf !important;
  color: #141619 !important;
}

.inner-wrapper.Available{
  color: #0f5132 !important;
  background-color: #d1e7dd !important;
  border-color: #badbcc !important;
}

.inner-wrapper.Booked .unit-name, .inner-wrapper.Booked .unit-value{
  color: #842029 !important;
}

.inner-wrapper.Blocked .unit-name, .inner-wrapper.Blocked .unit-value{
  color: #141619 !important;
}

.inner-wrapper.Available .unit-name, .inner-wrapper.Available .unit-value{
  color: #0f5132 !important;
}

.unit-name{
  font-size: 12px;
  font-weight: 600;
  line-height: 15.12px;
  color: rgba(0, 0, 0, 1);
  white-space: nowrap;
}

.unit-value{
  font-size: 12px;
  font-weight: 500;
  line-height: 15.12px;
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
}

@media(min-width: 1200px) and (max-width: 1700px){
  .unit-name, .unit-value{
    font-size: 11.5px;
  }
  .inner-wrapper{
    padding: 10px 6px;
  }
}

@media(min-width: 1200px) and (max-width: 1600px){
  .unit-name, .unit-value{
    font-size: 11px;
  }
}

@media(min-width: 1200px) and (max-width: 1450px){
  .unit-name, .unit-value{
    font-size: 10px;
    font-weight: 600;
  }
}

@media(min-width: 1200px) and (max-width: 1340px){
  .unit-name, .unit-value{
    font-size: 9.5px;
    font-weight: 600;
  }
}

@media(min-width: 1200px) and (max-width: 1340px){
  .unit-name, .unit-value{
    font-size: 9px;
    font-weight: 600;
  }
}

</style>